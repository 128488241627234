import Link from 'next/link'
import React from "react"
import Image from 'next/image'

export default function Footer() {
    let currentYear = (new Date).getFullYear();
    return (
        <footer className="text-center text-lg-start">
            <div className="container p-4" style={{"paddingBottom":"0px !important"}}>
                <div className="row">
                    <div className="col-lg-6 col-md-12 mb-4 mb-md-0">
                        <Image className="navbar-brand" width="212" height="40" src="/images/logo-foot.png"
                               alt={"IrsExtensions.Online logo"}/>
                        <h5 className="text-uppercase" style={{"marginTop":"20px"}}>Important Disclaimers:</h5>
                        <p>
                            irs-extension.online is designated as an Authorized IRS e-file
                            Provider. An Authorized IRS e-file Provider (Provider) is a business or organization
                            authorized by the
                            IRS to participate in the IRS e-file program.
                        </p>
                    </div>
                    <div className="col-lg-3 col-md-6 mb-4 mb-md-0">
                        <h5 className="text-uppercase">Links</h5>

                        <ul className="list-unstyled mb-0">
                            <li>
                                <Link href="/">
                                    <a>Home</a>
                                </Link>
                            </li>
                            <li>
                                <Link href="/login">
                                    <a> Customer Login </a>
                                </Link>
                            </li>
                            <li>
                                <Link href="/contact-us">
                                    <a> Contact Us </a>
                                </Link>
                            </li>

                            <li>
                                <Link href="https://blog.irs-extension.online">
                                    <a>Blog</a>
                                </Link>
                            </li>
                        </ul>
                    </div>
                    <div className="col-lg-3 col-md-6 mb-4 mb-md-0">
                        <h5 className="text-uppercase mb-0">About</h5>

                        <ul className="list-unstyled">
                            <li>
                                <Link href="/faq">
                                    <a >FAQ</a>
                                </Link>
                            </li>
                            <li>
                                <Link href="/terms-of-use">
                                    <a >Terms of Use
                                    </a>
                                </Link>
                            </li>
                            <li>
                                <Link href="/privacy-policy"><a>Privacy Policy</a></Link>
                            </li>
                            <li>
                                <Link href="/refund-policy"><a>Refund policy</a></Link>
                            </li>
                        </ul>
                    </div>
                    <div className="col-lg-12 col-md-12 mb-4 mb-md-0">
                        <div className="ssl">
                            <Image src="/images/ssl1.png" width="65" height="65" alt="ssl protected"/>
                            <span>  </span>
                            <Image src="/images/ssl2b.png" width="65" height="65" alt="ssl protected 2"/>
                            <span>  </span>
                            <Image src="/images/ssl3.png" width="65" height="65" alt="ssl protected 3"/>
                        </div>
                    </div>
                </div>
            </div>
            <div className="text-center p-3">
                © {currentYear}
                &nbsp;<a href={"https://" + process.env.NEXT_PUBLIC_WEBSITE_HOST}>{process.env.NEXT_PUBLIC_WEBSITE_NAME}</a>&nbsp;
                All right reserved
            </div>
        </footer>
    )
}
