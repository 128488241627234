import Head from 'next/head'
import Footer from "./footer"
import Header from "./header"
import {FingerprintContext} from "./context";
import React, {useEffect, useState} from "react";
import FingerprintJS from "@fingerprintjs/fingerprintjs";

let fpPromise = null;

if (typeof window !== 'undefined') {
    fpPromise = FingerprintJS.load();
}


export default function Layout({
                                   children,
                                   title = process.env.WEBSITE_NAME,
                                   description = 'Extens your tax return through October 15th',
                                   preHeader = <></>,
                               }) {

    const [fingerprint, setFingerprint] = useState(null);
    const [showTrackers, setShowTrackers] = useState(false);

    useEffect(() => {
        if (typeof window !== 'undefined' && fpPromise != null) {
            ;(async () => {
                // Get the visitor identifier when you need it.
                const fp = await fpPromise
                const result = await fp.get()

                // This is the visitor identifier:
                const visitorId = result.visitorId
                console.log("visitorId: " + visitorId);
                setFingerprint(visitorId);
            })()
        }
    }, []);

    return (
        <>
            <Head>
                <title>{title}</title>
                <meta httpEquiv="Content-Type" content="text/html; charset=utf-8"/>
                <meta charSet="utf-8"/>
                <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=5"/>
                <meta name="description" content={description}/>
                <meta name="author" content={process.env.website_name}/>
                <meta name="facebook-domain-verification" content="b81pw5hxpcjcyvbfvb6325z8qr1192" />
            </Head>
            <FingerprintContext.Provider value={{str: fingerprint}}>
                <header className="home-header" id="top">
                    <Header/>
                    {preHeader}
                </header>
                {children}
            </FingerprintContext.Provider>
            <Footer />
        </>
    )
}
