import Link from 'next/link'
import {Collapse, Navbar, NavbarToggler, NavbarBrand, Nav, NavItem, NavLink} from 'reactstrap';
import React, {useState} from 'react';
import styles from './topMenu.module.css';
import Image from 'next/image'
import {ctaEvent} from "../utils/events";

export default function Header() {
    let currentYear = (new Date).getFullYear();
    const topFunction = () => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }
    const [collapsed, setCollapsed] = useState(true);
    const toggleNavbar = () => setCollapsed(!collapsed);

    return (
        <>
            <div className="menu-container">
                <div className="container">
                    <Navbar color="faded" light expand="lg">
                        <NavbarBrand href="/" className="mr-auto">
                            <Link href="/">
                                <Image src="/images/logo.png" width={258} height={49} alt="IrsExtensions.Online Logo"/>
                            </Link>
                        </NavbarBrand>
                        <Nav navbar className="ml-auto">                                
                            <NavItem className="lastmenuli">
                                <Link href="/submit-extension" onClick={() => ctaEvent("header-cta")}>
                                    <a href="/submit-extension" onClick={() => ctaEvent("header-cta")} className="top-anchor icon cta-btn" id="header-cta">
                                        Start Here
                                    </a>
                                </Link>
                            </NavItem>
                        </Nav>
                    </Navbar>
                </div>
            </div>
        </>
    )
}


