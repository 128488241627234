import Link from 'next/link'
import CovidWarning from './covidWarning'
import TopMenu from './topMenu'

export default function Header() {
    const showCovidWarning = process.env.NEXT_PUBLIC_COVID_WARNING;
    if (showCovidWarning === true || showCovidWarning === "true") {
        return (
            <>
                <CovidWarning></CovidWarning>
                <TopMenu/>
            </>
        )
    }

    return (
        <TopMenu/>
    )
}
